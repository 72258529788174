module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jamie Samuel - Full-Stack Web Developer | Portfolio","short_name":"Jamie Samuel - Full-Stack Web Developer","description":"Jamie Samuel is a self-driven, charismatic, and detail-oriented Full-Stack Web Developer with a Chemical & Biological Engineering background, based in Vancouver, British Columbia. He loves collaborating cross-functionally to deliver innovative and engaging solutions across brand design, content creation, and web development.","lang":"en","start_url":"/","background_color":"#FFFFFF","theme_color":"#050B1E","display":"standalone","icon":"static/images/logo.svg","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5ae40cb8074d64eb68bbe9ce3424824c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-V9BL9W7TYJ"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
